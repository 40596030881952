import React, { useCallback, useState, useEffect } from "react";
import block from "bem-cn";
import { ICard } from "../../redux/types/app";
import { ReactComponent as SubmitIcon } from "../../assets/icons/icon-submit.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/icon-cancel.svg";
import Loader from "../Loader/Loader";
import { GameStatuses } from "../../redux/types/game";
import { CARDS_HASH } from "../../utils/consts";
import { getCardSkinKey, CardSkinKey } from "../../utils";
import "./Cards.scss";

interface IPropTypes {
  list: ICard[];
  onClickCard: (id: ICard["id"]) => void;
  gameStatus: GameStatuses;
}

const b = block("Cards");

// TODO: don't like how works showing/hidding selected card
// maybe need to use some carusel or make own to avoid manipulations with 'position: fixed'
// check details how it was in git history and https://jira.x2sy.com/browse/STVG-61

const CARD_SKIN_KEY: CardSkinKey = getCardSkinKey();

const Cards: React.FC<IPropTypes> = React.memo(
  ({ list, onClickCard, gameStatus }) => {
    const [selectedCard, setSelectedCard] = useState<ICard | undefined>();
    const [isCardSubmited, setCardSubmited] = useState(false);
    const [savedScrollY, setSavedScrollY] = useState(0);

    const onSelectCard = useCallback(
      (id: ICard["id"]) => {
        setSelectedCard(list.find((c) => c.id === id));
        setSavedScrollY(window.scrollY);
      },
      [list]
    );

    const onResetSelected = useCallback(() => {
      setSelectedCard(undefined);
    }, []);

    const onSubmitSelected = useCallback(() => {
      if (selectedCard) {
        setCardSubmited(true);
      }
    }, [selectedCard]);

    const onCardSubmitAnimationEnd = useCallback(
      ({ animationName }) => {
        if (animationName === "slide-up-out" && selectedCard) {
          onClickCard(selectedCard.id);
          onResetSelected();
        }
      },
      [onClickCard, selectedCard, onResetSelected]
    );

    useEffect(() => {
      if (selectedCard) {
        onResetSelected();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameStatus]);

    useEffect(() => {
      if (!selectedCard && savedScrollY) {
        window.scrollTo(0, savedScrollY);
        setSavedScrollY(0);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCard]);

    return (
      <div
        className={b({
          State_Covered: Boolean(savedScrollY),
        })}
      >
        <div
          className={b("Wrapper", {
            State_Submited: isCardSubmited,
            State_Selected: Boolean(selectedCard),
          })}
          onAnimationEnd={onCardSubmitAnimationEnd}
        >
          {list.map((c, i) => (
            <CardItem
              key={c.id}
              id={c.id}
              isSelected={selectedCard ? selectedCard.id === c.id : false}
              index={i}
              imgUrl={c[CARD_SKIN_KEY] || c.skin} // extra fallback
              onClickCard={onSelectCard}
              showLoader={true}
              selectedControls={
                <div
                  className={b("SelectedControls", {
                    State_Submited: isCardSubmited,
                  })}
                >
                  <SubmitIcon onClick={onSubmitSelected} />
                  <CancelIcon onClick={onResetSelected} />
                </div>
              }
            />
          ))}
        </div>
      </div>
    );
  }
);

Cards.displayName = "Cards";

export default Cards;

interface ICardItemPropTypes {
  id: ICard["id"];
  showLoader: boolean;
  imgUrl: ICard["skin"];
  isSelected: boolean;
  onClickCard?: (id: ICard["id"]) => void;
  index?: number;
  selectedControls: React.ReactNode;
}

const CardItem: React.FC<ICardItemPropTypes> = React.memo(
  ({ id, imgUrl, onClickCard, showLoader, isSelected, selectedControls }) => {
    const [skinLoaded, setSkinLoaded] = useState(!showLoader);
    const onClick = useCallback(() => onClickCard && onClickCard(id), [
      id,
      onClickCard,
    ]);
    const onSkinLoaded = useCallback(() => setSkinLoaded(true), []);

    // can't use img tag, sometimes problem on ios with render
    return (
      <div className={b("ItemWrapper", { State_Selected: isSelected })}>
        <div
          className={b("Item", { State_Loaded: skinLoaded })}
          onClick={onClick}
        >
          {!skinLoaded && <Loader />}
          <img
            className={b("ItemImg")}
            src={`${imgUrl}?ts=${CARDS_HASH}`}
            onLoad={onSkinLoaded}
            alt="card"
          />
          <div
            className={b("ItemSkin", { State_Loaded: skinLoaded })}
            style={{ backgroundImage: `url(${imgUrl}?ts=${CARDS_HASH})` }}
          />
        </div>
        {isSelected && selectedControls}
      </div>
    );
  }
);

CardItem.displayName = "CardItem";
