import { concat as _concat, omit as _omit } from "lodash";
import firebase from "firebase";
import { DocumentReference, WriteBatch } from "@firebase/firestore-types";
import {
  app as Firebase,
  COLLECTION_GAMES,
  COLLECTION_ROUNDS,
  COLLECTION_PLAYERS,
  SCORE_LIMIT
} from "./common";
import { IGameData } from "../redux/types/game";

// this data should calculate into game state
// and not needed to store
const fieldsToOmit = [
  "ref",
  "roundRef",
  "playersRef",
  "players",
  "roundState",
  "roundResults",
  "deck"
];

export const getGame = async (id: string): Promise<DocumentReference> => {
  return Firebase.getFirestore()
    .collection(COLLECTION_GAMES)
    .doc(id);
};

export const getDeckFromDatabase = async (id: string) => {
  const cards = await Firebase.getFirestore()
    .collection("decks")
    .doc(id)
    .collection("cards")
    .get();

  return cards.docs.map(i => i.data());
};

export const getUsedDecks = async (list: any[]) => {
  const decks = await Promise.all(list.map(d => getDeckFromDatabase(d)));
  return { cards: _concat([], ...decks) };
};

export const getRounds = async (ref: DocumentReference) => {
  return ref.collection(COLLECTION_ROUNDS);
};

export const getCurrentRound = async (
  gameRef: DocumentReference,
  currentRound: IGameData["currentRound"]
) => {
  const roundsRef = await getRounds(gameRef);

  if (currentRound === null) return Promise.reject(roundsRef);

  const roundRef = roundsRef.doc(currentRound);
  const round = await roundRef.get();

  return {
    data: round.data(),
    ref: roundRef
  };
};

export const getPlayers = async (ref: DocumentReference) => {
  const playersRef = ref.collection(COLLECTION_PLAYERS);
  const snapshot = await playersRef.get();

  return {
    playersRef,
    snapshot
  };
};

export const connectOrGetPlayer = async (
  gameRef: DocumentReference,
  userId: string
) => {
  const { playersRef } = await getPlayers(gameRef);
  const playerRef = await playersRef.doc(userId);

  return playerRef;
};

export const getWinners = (gameRef: DocumentReference) =>
  gameRef
    .collection(COLLECTION_PLAYERS)
    .where("score", ">=", SCORE_LIMIT)
    .get();

export const createBatchRecord = (): WriteBatch =>
  Firebase.getFirestore().batch();

export const union = (data: any) =>
  firebase.firestore.FieldValue.arrayUnion(data);

export const createTransaction = (updFn: any) =>
  Firebase.getFirestore().runTransaction(updFn);

export const updateRef = async (
  ref: DocumentReference,
  data: object,
  merge: boolean = true
): Promise<DocumentReference> => {
  await ref.set(JSON.parse(JSON.stringify(_omit(data, fieldsToOmit))), {
    merge
  });
  return ref;
};

export const connectExistsGame = (gameId: string, playerId: string) =>
  Firebase.callFn("connectExistsGame", {
    gameId,
    playerId
  });

export const connectNewGame = (gameId: string, username: string) =>
  Firebase.callFn("connectNewGame", { gameId, username, clientTS: Date.now() });

export const leaveGame = (gameId: string, playerId: string) =>
  Firebase.callFn("leaveGame", { gameId, playerId });

export const findGameByCode = (code: string) =>
  Firebase.callFn("findGameByCode", { code });
