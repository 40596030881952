import { Dispatch, Middleware, MiddlewareAPI } from "redux";
import { Transaction } from "@firebase/firestore-types";
import { IAppAction } from "../utils";
import { createTransaction, createBatchRecord } from "../../database/utils";
import { INSERT_MOVE, roundActions, INSERT_VOTE } from "../ducks/round";
import { roundRefSelector } from "../selectors/round";
import { playerRefSelector, playerDataSelector } from "../selectors/app";
import { logError } from "../../utils/error";
import { gameDataSelector } from "../selectors/game";

const middleware: Middleware = ({ dispatch, getState }: MiddlewareAPI) => (
  next: Dispatch
) => async (action: IAppAction) => {
  if (action.type === INSERT_MOVE) {
    const {
      payload: { cardId },
    } = action as ReturnType<typeof roundActions.insertMove>;
    const state = getState();
    const playerRef = playerRefSelector(state);
    const roundRef = roundRefSelector(state);
    const player = playerDataSelector(state);

    const batch = createBatchRecord();

    if (roundRef && playerRef) {
      batch.set(
        roundRef,
        {
          moves: { [playerRef.id]: cardId },
        },
        { merge: true }
      );

      batch.update(playerRef, {
        currentCards: player.currentCards.filter((id) => cardId !== id),
      });

      batch.commit().catch((error) => {
        const gameData = gameDataSelector(state);

        logError("insert move error", {
          playerId: player.id,
          gameId: gameData.id,
          roundId: gameData.currentRound,
          error,
        });
      });
    }
  } else if (action.type === INSERT_VOTE) {
    const {
      payload: { cardId },
    } = action as ReturnType<typeof roundActions.insertVote>;
    const state = getState();
    const playerRef = playerRefSelector(state);
    const roundRef = roundRefSelector(state);

    if (roundRef && playerRef) {
      createTransaction(async (tr: Transaction) =>
        tr.set(
          roundRef,
          {
            votes: {
              [playerRef.id]: cardId,
            },
          },
          { merge: true }
        )
      ).catch((error) => {
        const gameData = gameDataSelector(state);
        const playerData = playerDataSelector(state);

        logError("insert vote error", {
          gameId: gameData.id,
          playerId: playerData.id,
          roundId: gameData.id,
          error,
        });
      });
    }
  }

  return next(action);
};

export default middleware;
