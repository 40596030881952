import React from "react";
import { useTranslation } from "react-i18next";
import block from "bem-cn";
import Loader from "../Loader/Loader";
import "./ExitPopup.scss";

const b = block("ExitPopup");

interface IExitPopupPropTypes {
  onHide: () => void;
  onExitGame: () => void;
  isFetchingExit: boolean;
  exitWasClicked: boolean;
}

const ExitPopup: React.FC<IExitPopupPropTypes> = React.memo(
  ({ onHide, onExitGame, isFetchingExit, exitWasClicked }) => {
    const { t } = useTranslation();

    return (
      <div className={b("Overlay")}>
        {isFetchingExit ? (
          <Loader />
        ) : (
          <div className={b("Wrapper", { State_Hidden: exitWasClicked })}>
            <div className={b("Text")}>{t("areYouSureWantToExit")}</div>
            <div className={b("Controls")}>
              <div className="Button" onClick={onHide}>
                {t("stay")}
              </div>
              <div className="Button" onClick={onExitGame}>
                {t("exit")}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);

ExitPopup.displayName = "ExitPopup";

export default ExitPopup;
