import { createSelector } from "reselect";
import { IState } from "../types";

export const appSelector = (state: IState) => state.app;

export const stepSelector = createSelector(appSelector, app => app.step);

export const playerRefSelector = createSelector(
  appSelector,
  app => app.player.ref
);

export const playerDataSelector = createSelector(
  appSelector,
  app => app.player.data
);

export const cardsSelector = createSelector(appSelector, app => app.cards);
