import React, { useState, useMemo } from "react";
import useInterval from "../../hooks/useInterval";

interface IPropTypes {
  initialValue: number;
  max?: number;
  isRunning: boolean;
  onTimerDone: () => void;
  className?: string;
  size?: number;
  stroke?: string;
  strokeWidth?: number;
  getValue?: () => number;
}

const ProgressBarCircle: React.FC<IPropTypes> = React.memo(
  ({
    size = 90,
    stroke = "#DFC00C",
    strokeWidth = 10,
    initialValue,
    max = 59,
    className,
    onTimerDone,
    isRunning,
    getValue,
  }) => {
    const [value, setValue] = useState(initialValue);
    const newValue = useMemo(() => (getValue ? getValue : () => value - 1), [
      getValue,
      value,
    ]);

    useInterval(
      () => (value < 1 ? onTimerDone() : setValue(newValue)),
      isRunning ? 1000 : null
    );

    const radius = (size - strokeWidth) / 2;
    const viewBox = `0 0 ${size} ${size}`;
    const dashArray = radius * Math.PI * 2 - 5;
    const dashOffset = dashArray - (dashArray * value) / max;

    return (
      <div className={className}>
        <svg width={size} height={size} viewBox={viewBox}>
          <defs>
            <filter id="blur">
              <feGaussianBlur stdDeviation="1" />
            </filter>
          </defs>
          <circle
            fill={"none"}
            stroke={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
            transform={`rotate(-90 ${size / 2} ${size / 2})`}
            style={{
              transition: "stroke-dashoffset 1s linear",
            }}
            shapeRendering={"geometricPrecision"}
          />
        </svg>
        <span>{value}</span>
      </div>
    );
  }
);

ProgressBarCircle.displayName = "ProgressBarCircle";

export default ProgressBarCircle;
