export interface IUiState {
  gameFetchStatus: FetchStatus;
  isFetchingLeaveGame: boolean;
  cardsFetchStatus: FetchStatus;
}

export enum FetchStatus {
  idle = "idle",
  fetching = "fetching",
  failed = "failed",
  success = "success",
}
