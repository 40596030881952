import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { composeWithDevTools as composeWithDevToolsProd } from "redux-devtools-extension/logOnlyInProduction";
import rootReducer from "./ducks";
import middlewares from "./middlewares";

const composeDevToolsModules =
  process.env.NODE_ENV === "production"
    ? composeWithDevToolsProd
    : composeWithDevTools;
const composeEnhancers = composeDevToolsModules.call(composeDevToolsModules, {
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
