import { Dispatch, Middleware, MiddlewareAPI } from "redux";
import { difference } from "lodash";
import { IAppAction } from "../utils";
import {
  appActions,
  LEAVE_GAME,
  SUBSCRIBE_ON_USER,
  SUBSCRIBE_ON_GAME,
  SUBSCRIBE_ON_ROUND,
  UNSUBSCRIBE_FROM_GAME_ENTITIES,
  GET_CARDS,
} from "../ducks/app";
import { gameDataSelector, gameRefSelector } from "../selectors/game";
import { leaveGame, getCurrentRound, getUsedDecks } from "../../database/utils";
import { clearCookies } from "../../utils";
import { CN } from "../../utils/consts";
import { playerDataSelector } from "../selectors/app";
import { IPlayer, ICard } from "../types/app";
import { roundRefSelector } from "../selectors/round";
import { gameActions } from "../ducks/game";
import { roundActions } from "../ducks/round";
import { IRoundState, IRoundData } from "../types/round";
import { IGameState, IGameData } from "../types/game";
import { DocumentSnapshot, DocumentData } from "@firebase/firestore-types";
import { logError } from "../../utils/error";

let unsubscribeUser = () => {};
let unsubscribeGame = () => {};
let unsubscribeRound = () => {};

const middleware: Middleware = ({ dispatch, getState }: MiddlewareAPI) => (
  next: Dispatch
) => (action: IAppAction) => {
  if (action.type === LEAVE_GAME.fetch) {
    const state = getState();
    const gameData = gameDataSelector(state);
    const playerData = playerDataSelector(state);

    leaveGame(gameData.id, playerData.id)
      .then((response) => {
        if (response.data.leaved) {
          clearCookies([CN.PLAYER_ID, CN.GAME_ID]);

          // TODO: find another way to reset all
          window.location.reload();
        } else {
          dispatch(
            appActions.leaveGame.fail({
              error: `leaved: ${response.data.leaved}`,
            })
          );
        }
      })
      .catch((error) => {
        console.log("leave game error ", error);
        dispatch(appActions.leaveGame.fail({ error }));
        window.location.reload();
        logError("leave game error", {
          playerId: playerData.id,
          gameId: gameData.id,
          error,
        });
      });
  } else if (action.type === SUBSCRIBE_ON_USER) {
    const { payload } = action as ReturnType<typeof appActions.subscribeOnUser>;

    if (payload) {
      unsubscribeUser = payload.onSnapshot(
        (doc: DocumentSnapshot<DocumentData>) => {
          const data = doc.data();

          data &&
            dispatch(appActions.getPlayer.success(data as IPlayer["data"]));
        }
      );
    }
  } else if (action.type === SUBSCRIBE_ON_GAME) {
    const { payload } = action as ReturnType<typeof appActions.subscribeOnGame>;

    if (payload) {
      unsubscribeGame = payload.onSnapshot((doc) => {
        const state = getState();
        const roundRef = roundRefSelector(state);
        const gameRer = gameRefSelector(state);
        const gameData = gameDataSelector(state);
        const data = doc.data() as IGameData;
        const setRound = (roundState: IRoundState) =>
          dispatch(roundActions.setRound(roundState));

        if (data) {
          if (data.currentRound && !roundRef) {
            getRound(data.currentRound, gameRer, setRound);
          }

          if (roundRef && roundRef.id !== data.currentRound) {
            getRound(data.currentRound, gameRer, setRound);
          }

          if (!!difference(gameData.usedDecks, data.usedDecks).length) {
            dispatch(appActions.getCards.fetch(data.usedDecks));
          }

          dispatch(gameActions.getGame.success({ data }));
        }
      });
    }
  } else if (action.type === SUBSCRIBE_ON_ROUND) {
    const { payload } = action as ReturnType<
      typeof appActions.subscribeOnRound
    >;
    const setRound = (roundState: IRoundState) =>
      dispatch(roundActions.setRound(roundState));

    if (payload) {
      unsubscribeRound = payload.onSnapshot((doc) => onRound(doc, setRound));
    }
  } else if (action.type === UNSUBSCRIBE_FROM_GAME_ENTITIES) {
    unsubscribeUser();
    unsubscribeGame();
    unsubscribeRound();
  } else if (action.type === GET_CARDS.fetch) {
    const { payload } = action as ReturnType<typeof appActions.getCards.fetch>;
    const gameData = gameDataSelector(getState());

    getUsedDecks(payload)
      .then(({ cards }) => {
        if (cards) {
          dispatch(appActions.getCards.success(cards as ICard[]));
        } else {
          dispatch(
            appActions.getCards.fail({ message: "no cards in response" })
          );

          logError("fetching cards error", {
            gameId: gameData.id,
            error: {
              message: "no cards in response",
            },
          });
        }
      })
      .catch((error) => {
        dispatch(appActions.getCards.fail({ message: "cards not fetched" }));

        logError("fetching cards error", {
          gameId: gameData.id,
          error,
        });
      });
  }

  return next(action);
};

export default middleware;

function onRound(
  doc: DocumentSnapshot<DocumentData>,
  setRound: (state: IRoundState) => void
) {
  const data = doc.data();

  data && setRound({ data: data as IRoundData });
}

async function getRound(
  roundId: IGameData["currentRound"],
  gameRef: IGameState["ref"],
  setRound: (state: IRoundState) => void
) {
  unsubscribeRound();

  if (gameRef) {
    const { data, ref } = await getCurrentRound(gameRef, roundId);

    setRound({ data: data as IRoundData, ref });
    unsubscribeRound = ref.onSnapshot((doc) => onRound(doc, setRound));
  }
}
