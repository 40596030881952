const appName = 'img-web-client';

export type TypeCreator = (type: string) => string;

export interface IAppAction {
  type: string;
  payload: any;
}

export const createActionType = (
  reducer: string,
  actionName?: string,
): TypeCreator | string => {
  if (!actionName) {
    return (name: string) => `${appName}/${reducer}/${name.toUpperCase()}`;
  } else {
    return `${appName}/${reducer}/${actionName.toUpperCase()}`;
  }
};

export interface IAction<T extends string, P> {
  type: T;
  payload: P;
}
export function createAction<T extends string>(type: T): IAction<T, undefined>;
export function createAction<T extends string, P>(
  type: T,
  payload: P,
): IAction<T, P>;
export function createAction<T extends string, P>(type: T, payload?: P) {
  return { type, payload };
}

export const createFetchTypes = (type: string) => ({
  fetch: `${type}_FETCH`,
  success: `${type}_SUCCESS`,
  fail: `${type}_FAIL`,
});

export function createFetchActionCreators<FETCH, SUCCESS = any, FAIL = any>(
  fetchTypes: ReturnType<typeof createFetchTypes>,
) {
  return {
    fetch: (arg: FETCH) => createAction(fetchTypes.fetch, arg),
    success: (arg: SUCCESS) => createAction(fetchTypes.success, arg),
    fail: (arg: FAIL) => createAction(fetchTypes.fail, arg),
  };
}
