import queryString from "query-string";
import cookies from "js-cookie";
import { KIBANA_URL } from "./consts";

export const clearCookies = (names: string[]) =>
  names.forEach((n) => cookies.remove(n, { path: "" }));

export const updateQueryParam = (key: string, value: string | undefined) => {
  const parsed = {
    ...queryString.parse(window.location.search),
    [key]: value,
  };

  const path =
    window.location.origin +
    window.location.pathname +
    "?" +
    queryString.stringify(parsed);

  window.history.pushState({ path }, "", path);
};

export const clearQueryParams = () => {
  const path = window.location.origin + window.location.pathname;

  window.history.pushState({ path }, "", path);
};

export const getTimerValue = (
  timeout: number, // s
  started: number, // s
  playerOffset: number // ms
) => {
  let res;

  res = timeout - (Date.now() - (started * 1000 - playerOffset)) / 1000;

  return res > 0 ? Math.round(res) : 0;
};

export type CardSkinKey = "1" | "2" | "3" | "skin";

export const getCardSkinKey = () => {
  let res = "skin";
  const dpr = Math.round(window.devicePixelRatio);

  switch (dpr) {
    case 1:
      res = "1";
      break;

    case 2:
    case 3: // TODO: experimental, for super retina cards are loading so slow
      res = "2";
      break;
  }

  return res as CardSkinKey;
};

export const postKibanaLog = (text: string, data: { [key: string]: any }) =>
  fetch(KIBANA_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    body: `text=${text}&data=${encodeURIComponent(JSON.stringify(data))}`,
  });

export const getTruncatedString = (text = "", length = 10) =>
  text.length > length ? `${text.substring(0, length)}...` : text;
