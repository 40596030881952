import { KIBANA_NAME, KIBANA_ERROR_TYPE } from "./consts";
import { version } from "../../package.json";
import { postKibanaLog } from ".";

export function logError(message: string, errorObj?: any) {
  let data: { [key: string]: any } = {
    name: KIBANA_NAME,
    error: `[mob-client]: ${message}`,
    version,
    userAgent: window.navigator.userAgent,
    platform: window.navigator.platform || "",
  };

  if (typeof errorObj === "object") {
    data = {
      ...data,
      ...errorObj,
    };
  }

  postKibanaLog(KIBANA_ERROR_TYPE, data);
}
