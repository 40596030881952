import React, { useEffect, useCallback, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import block from "bem-cn";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { gameActions } from "../../redux/ducks/game";
import { gameFetchStatusSelector } from "../../redux/selectors/ui";
import Loader from "../Loader/Loader";
import { FetchStatus } from "../../redux/types/ui";
import { appActions } from "../../redux/ducks/app";
import { AppSteps } from "../../redux/types/app";
import { updateQueryParam } from "../../utils";
import "./GameCodeInput.scss";

interface PropTypes {}

const b = block("GameCodeInput");

const ROOM_ID_LENGTH = 4;
const REMOVE_VALUE_BY_KEYS = ["Backspace", "Delete"];

const GameCodeInput: React.FC<PropTypes> = React.memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const gameFetchStatus = useSelector(gameFetchStatusSelector);

  const inputsContainerRef = useRef<HTMLDivElement>(null);

  const [gameCode, setGameCode] = useState<string[]>([]);

  const onInputKeyUp = useCallback(
    ({ key, target }) => {
      if (REMOVE_VALUE_BY_KEYS.includes(key)) {
        gameCode[target.name] = "";
        setGameCode([...gameCode]);
        target.previousSibling && target.previousSibling.focus();
      }
    },
    [gameCode]
  );

  const onChange = useCallback(
    ({ target }) => {
      if (target.value.length === 1) {
        gameCode[target.name] = target.value;
        setGameCode([...gameCode]);
        target.nextSibling && target.nextSibling.focus();
      } else if (target.value.length > 1) {
        target.nextSibling && target.nextSibling.focus();
      }
    },
    [gameCode]
  );

  const onShakingAnimationEnd = useCallback(({ animationName }) => {
    if (animationName === "shake-left-right") {
      setGameCode([]);

      const firstInput =
        inputsContainerRef.current &&
        (inputsContainerRef.current.firstElementChild as HTMLInputElement);

      firstInput && firstInput.focus();
    }
  }, []);

  const onSuccessAnimationEnd = useCallback(
    ({ animationName }) => {
      if (animationName === "height-out") {
        dispatch(appActions.setStep(AppSteps.enterName));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const code = gameCode.join("");
    if (code.length === ROOM_ID_LENGTH) {
      dispatch(gameActions.findGameByCode.fetch(code));

      const lastInput =
        inputsContainerRef.current &&
        (inputsContainerRef.current.lastElementChild as HTMLInputElement);

      lastInput && lastInput.blur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameCode]);

  useEffect(() => {
    const { code } = queryString.parse(window.location.search);

    if (typeof code === "string" && code.length === 4) {
      dispatch(gameActions.findGameByCode.fetch(code));
      setGameCode(code.split(""));
      updateQueryParam("code", undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={b()}>
      <div className={b("Title")}>{t("enterGameCode")}</div>
      <div
        className={b("Fields", {
          State_ClearValues: gameFetchStatus === FetchStatus.failed,
        })}
        onAnimationEnd={onShakingAnimationEnd}
        ref={inputsContainerRef}
      >
        <input
          name="0"
          type="number"
          inputMode="numeric"
          className={b("Field", {
            State_Success: gameFetchStatus === FetchStatus.success,
          })}
          onAnimationEnd={onSuccessAnimationEnd}
          onChange={onChange}
          onKeyUp={onInputKeyUp}
          value={gameCode[0] || ""}
        />
        <input
          name="1"
          type="number"
          inputMode="numeric"
          className={b("Field", {
            State_Success: gameFetchStatus === FetchStatus.success,
          })}
          onChange={onChange}
          onKeyUp={onInputKeyUp}
          value={gameCode[1] || ""}
        />
        <input
          name="2"
          type="number"
          inputMode="numeric"
          className={b("Field", {
            State_Success: gameFetchStatus === FetchStatus.success,
          })}
          onChange={onChange}
          onKeyUp={onInputKeyUp}
          value={gameCode[2] || ""}
        />
        <input
          name="3"
          type="number"
          inputMode="numeric"
          className={b("Field", {
            State_Success: gameFetchStatus === FetchStatus.success,
          })}
          onChange={onChange}
          onKeyUp={onInputKeyUp}
          value={gameCode[3] || ""}
        />
      </div>
      {gameFetchStatus === FetchStatus.fetching && (
        <div className={b("LoaderContainer")}>
          <Loader position="absolute" />
        </div>
      )}
    </div>
  );
});

GameCodeInput.displayName = "GameCodeInput";

export default GameCodeInput;
