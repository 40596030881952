import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ReactComponent as IconArrow } from "../../assets/icons/icon-arrow.svg";
import { gameDataSelector } from "../../redux/selectors/game";
import { gameFetchStatusSelector } from "../../redux/selectors/ui";
import { gameActions } from "../../redux/ducks/game";
import { FetchStatus } from "../../redux/types/ui";
import Loader from "../Loader/Loader";
import "./NameInput.scss";

interface PropTypes {}

const NameInput: React.FC<PropTypes> = React.memo(() => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const gameData = useSelector(gameDataSelector);
  const gameFetchStatus = useSelector(gameFetchStatusSelector);

  const onFormSubmit = useCallback(
    (e) => {
      e.preventDefault();

      const { value } = e.target.elements.userName;

      if (value) {
        dispatch(
          gameActions.getGame.fetch({
            gameCode: gameData.code,
            gameId: gameData.id,
            playerName: value,
          })
        );
      }
    },
    [dispatch, gameData]
  );

  useEffect(() => {
    // autoFocus attr not so good on ios
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 500);
  }, []);

  return (
    <form className="NameInput" onSubmit={onFormSubmit}>
      {gameFetchStatus === FetchStatus.fetching ? (
        <Loader />
      ) : (
        <>
          <div className="NameInput__Title Title">{t("whatIsYourName")}</div>
          <input name="userName" ref={inputRef} className="NameInput__Field" />
          <button className="NameInput__Submit">
            <div className="Button">
              <IconArrow />
            </div>
          </button>
        </>
      )}
    </form>
  );
});

NameInput.displayName = "NameInput";

export default NameInput;
