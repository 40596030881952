import { createSelector } from "reselect";
import { IState } from "../types";

export const gameSelector = (state: IState) => state.game;

export const gameDataSelector = createSelector(
  gameSelector,
  (game) => game.data
);

export const gameIdSelector = createSelector(
  gameDataSelector,
  (game) => game.id
);

export const gameCodeSelector = createSelector(
  gameDataSelector,
  (game) => game.code
);

export const gameRefSelector = createSelector(gameSelector, (game) => game.ref);

export const gameStatusSelector = createSelector(
  gameSelector,
  (game) => game.data.status
);

export const winnersSelector = createSelector(
  gameSelector,
  (game) => game.winners
);

export const gameStatusUpdatedSecondsSelector = createSelector(
  gameDataSelector,
  (game) => (game.statusUpdated ? game.statusUpdated.seconds : 0)
);

export const gamePauseSelector = createSelector(gameDataSelector, (game) =>
  game.pause
    ? game.pause
    : {
        enabled: false,
        secondsLeft: 0,
        updated: { seconds: 0, nanoseconds: 0 },
      }
);
