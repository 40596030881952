import produce from "immer";
import {
  createActionType,
  TypeCreator,
  IAppAction,
  createAction
} from "../utils";
import { IRoundState } from "../types/round";
import { ICard, IPlayer } from "../types/app";

const createType = createActionType("round") as TypeCreator;

export const SET_ROUND = createType("SET_ROUND");
export const INSERT_MOVE = createType("INSERT_MOVE");
export const INSERT_VOTE = createType("INSERT_VOTE");
export const CLEAR_VOTES_AND_MOVES = createType("CLEAR_VOTES_AND_MOVES");
export const RESET_MASTER = createType("RESET_MASTER");

export const roundActions = {
  setRound: (round: { data: IRoundState["data"]; ref?: IRoundState["ref"] }) =>
    createAction(SET_ROUND, round),
  insertMove: (cardId: ICard["id"], playerId: IPlayer["data"]["id"]) =>
    createAction(INSERT_MOVE, { cardId, playerId }),
  insertVote: (cardId: ICard["id"], playerId: IPlayer["data"]["id"]) =>
    createAction(INSERT_VOTE, { cardId, playerId }),
  clearVotesAndMoves: () => createAction(CLEAR_VOTES_AND_MOVES),
  resetMaster: () => createAction(RESET_MASTER)
};

const initialState: IRoundState = {
  data: {
    master: {
      id: "",
      username: ""
    },
    moves: {},
    votes: {}
  }
};

export default function reducer(
  state: IRoundState = initialState,
  action: IAppAction
): IRoundState {
  switch (action.type) {
    case SET_ROUND:
      return produce(state, newState => {
        const {
          payload: { data, ref }
        } = action as ReturnType<typeof roundActions.setRound>;

        newState.data = data;

        if (ref) {
          newState.ref = ref;
        }
      });

    case INSERT_MOVE:
      return produce(state, newState => {
        const {
          payload: { cardId, playerId }
        } = action as ReturnType<typeof roundActions.insertMove>;

        newState.data.moves[playerId] = cardId;
      });

    case INSERT_VOTE:
      return produce(state, newState => {
        const {
          payload: { cardId, playerId }
        } = action as ReturnType<typeof roundActions.insertVote>;

        newState.data.votes[playerId] = cardId;
      });

    case CLEAR_VOTES_AND_MOVES:
      return produce(state, newState => {
        newState.data.votes = {};
        newState.data.moves = {};
      });

    case RESET_MASTER:
      return produce(state, newState => {
        newState.data.master = initialState.data.master;
      });
    default:
      break;
  }

  return state;
}
