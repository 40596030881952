import { Dispatch, Middleware, MiddlewareAPI } from "redux";
import { batch } from "react-redux";
import cookies from "js-cookie";
import { isEmpty as _isEmpty, omit as _omit } from "lodash";
import { IAppAction } from "../utils";
import {
  GET_GAME,
  gameActions,
  GET_WINNERS,
  SET_GAME_STATUS,
  FIND_GAME_BY_CODE,
} from "../ducks/game";
import {
  getGame,
  getWinners,
  updateRef,
  connectExistsGame,
  connectNewGame,
  connectOrGetPlayer,
  findGameByCode,
} from "../../database/utils";
import {
  IGameData,
  GameStatuses,
  IConnectExistGameResponse,
  IConnectNewGameResponse,
} from "../types/game";
import { appActions } from "../ducks/app";
import { IPlayer, AppSteps } from "../types/app";
import { roundActions } from "../ducks/round";
import { gameRefSelector, gameDataSelector } from "../selectors/game";
import { roundDataSelector } from "../selectors/round";
import { CN, COOKIES_LIFE_TIME } from "../../utils/consts";
import { clearCookies } from "../../utils";
import { logError } from "../../utils/error";
import { playerDataSelector } from "../selectors/app";

const middleware: Middleware = ({ dispatch, getState }: MiddlewareAPI) => (
  next: Dispatch
) => async (action: IAppAction) => {
  if (action.type === GET_GAME.fetch) {
    const {
      payload: { playerName, gameId, playerId },
    } = action as ReturnType<typeof gameActions.getGame.fetch>;

    const onError = (e: any) => {
      console.warn("reconnect error ", e);

      batch(() => {
        dispatch(gameActions.getGame.fail({ error: 500 }));
        dispatch(appActions.setStep(AppSteps.enterGameCode));
      });

      logError("game connection error", {
        gameId: gameId || "new",
        playerId: playerId || "new",
        error: e,
      });
    };

    if (gameId && playerId) {
      connectExistsGame(gameId, playerId)
        .then(async ({ data }: IConnectExistGameResponse) => {
          const gameRef = await getGame(gameId);
          const playerData = data.player as IPlayer["data"];
          const playerRef = await connectOrGetPlayer(gameRef, playerData.id);

          batch(() => {
            dispatch(
              gameActions.getGame.success({
                data: _omit(data.game, ["statusUpdated"]), // TODO: statusUpdated will be removed from game data
                ref: gameRef,
              })
            );

            dispatch(
              appActions.connectedToGameSuccess({
                player: { ref: playerRef, data: playerData },
              })
            );
          });
        })
        .catch(onError);
    } else if (gameId && playerName) {
      connectNewGame(gameId, playerName)
        .then(async ({ data }: IConnectNewGameResponse) => {
          cookies.set(CN.GAME_ID, data.gameId, {
            expires: COOKIES_LIFE_TIME,
          });
          cookies.set(CN.PLAYER_ID, data.player.id, {
            expires: COOKIES_LIFE_TIME,
          });

          const gameRef = await getGame(data.gameId);
          const gameData = await gameRef
            .get()
            .then((snapshot) => snapshot.data() as IGameData);
          const playerData = data.player as IPlayer["data"];
          const playerRef = await connectOrGetPlayer(gameRef, playerData.id);

          batch(() => {
            dispatch(
              gameActions.getGame.success({
                data: gameData,
                ref: gameRef,
              })
            );

            dispatch(
              appActions.connectedToGameSuccess({
                player: { ref: playerRef, data: playerData },
              })
            );
          });
        })
        .catch(onError);
    }
  } else if (action.type === GET_GAME.success) {
    const {
      payload: { data },
    } = action as ReturnType<typeof gameActions.getGame.success>;
    const state = getState();
    const { moves, votes } = roundDataSelector(state);

    if (
      data.status === GameStatuses.showResults &&
      (!_isEmpty(moves) || !_isEmpty(votes))
    ) {
      dispatch(roundActions.clearVotesAndMoves());
    }

    if (data.status === GameStatuses.finished && cookies.get(CN.PLAYER_ID)) {
      clearCookies([CN.PLAYER_ID, CN.GAME_ID]);
    }
  } else if (action.type === GET_WINNERS.fetch) {
    const state = getState();
    const gameRef = gameRefSelector(state);

    if (gameRef) {
      getWinners(gameRef)
        .then((winners) =>
          dispatch(
            gameActions.getWinners.success(
              winners.docs.map((w) => w.data() as IPlayer["data"])
            )
          )
        )
        .catch((e) => {
          const gameData = gameDataSelector(state);
          const playerData = playerDataSelector(state);

          logError("get winners error", {
            gameId: gameData.id,
            playerId: playerData.id,
            roundId: gameData.currentRound,
          });
        });
    }
  } else if (action.type === SET_GAME_STATUS) {
    const gameRef = gameRefSelector(getState());
    const { payload } = action as ReturnType<typeof gameActions.setGameStatus>;

    if (gameRef) {
      await updateRef(gameRef, { status: payload }).catch((error) => {
        const state = getState();
        const gameData = gameDataSelector(state);
        const playerData = playerDataSelector(state);

        logError("update game status error", {
          gameId: gameData.id,
          playerId: playerData.id,
          roundId: gameData.currentRound,
          error,
        });
      });

      const freshData = await gameRef.get();

      dispatch(
        gameActions.getGame.success({
          data: freshData.data() as IGameData,
          ref: gameRef,
        })
      );
    }
  } else if (action.type === FIND_GAME_BY_CODE.fetch) {
    const { payload } = action as ReturnType<
      typeof gameActions.findGameByCode.fetch
    >;

    findGameByCode(payload)
      .then((response) => {
        dispatch(
          gameActions.findGameByCode.success(response.data as IGameData)
        );
        dispatch(appActions.setStep(AppSteps.enterName));
      })
      .catch((error) => {
        logError("didn't find game by code", {
          gameCode: payload,
          error,
        });
        dispatch(gameActions.findGameByCode.fail(error));
      });
  }

  return next(action);
};

export default middleware;
