import produce from "immer";
import {
  createActionType,
  TypeCreator,
  createAction,
  IAppAction,
  createFetchTypes,
  createFetchActionCreators,
} from "../utils";
import { IAppState, AppSteps, ICard, IPlayer } from "../types/app";
import { initialUserState } from "../../database/common";
import { IRoundState } from "../types/round";
import { IGameState, IGameData } from "../types/game";

const createType = createActionType("app") as TypeCreator;

export const SET_STEP = createType("SET_STEP");
export const SET_CARDS = createType("SET_CARDS");
export const GET_PLAYER = createFetchTypes(createType("GET_PLAYER"));
export const SET_PLAYER_REF = createType("SET_PLAYER_REF");
export const CONNECTED_TO_GAME_SUCCESS = createType(
  "CONNECTED_TO_GAME_SUCCESS"
);
export const LEAVE_GAME = createFetchTypes(createType("LEAVE_GAME"));
export const SUBSCRIBE_ON_USER = createType("SUBSCRIBE_ON_USER");
export const SUBSCRIBE_ON_GAME = createType("SUBSCRIBE_ON_GAME");
export const SUBSCRIBE_ON_ROUND = createType("SUBSCRIBE_ON_ROUND");
export const UNSUBSCRIBE_FROM_GAME_ENTITIES = createType(
  "UNSUBSCRIBE_FROM_GAME_ENTITIES"
);
export const GET_CARDS = createFetchTypes(createType("GET_CARDS"));

export const appActions = {
  setStep: (step: AppSteps) => createAction(SET_STEP, step),
  setCards: (cards: ICard[]) => createAction(SET_CARDS, cards),
  setPlayerRef: (ref: IPlayer["ref"]) => createAction(SET_PLAYER_REF, ref),
  getPlayer: createFetchActionCreators<string, IPlayer["data"]>(GET_PLAYER),
  leaveGame: createFetchActionCreators<void>(LEAVE_GAME),
  connectedToGameSuccess: (data: { player: IPlayer }) =>
    createAction(CONNECTED_TO_GAME_SUCCESS, data),
  subscribeOnUser: (ref: IPlayer["ref"]) =>
    createAction(SUBSCRIBE_ON_USER, ref),
  subscribeOnGame: (ref: IGameState["ref"]) =>
    createAction(SUBSCRIBE_ON_GAME, ref),
  subscribeOnRound: (ref: IRoundState["ref"]) =>
    createAction(SUBSCRIBE_ON_ROUND, ref),
  unsubscribeFromGameEntities: () =>
    createAction(UNSUBSCRIBE_FROM_GAME_ENTITIES),
  getCards: createFetchActionCreators<IGameData["usedDecks"], ICard[]>(
    GET_CARDS
  ),
};

const initialState: IAppState = {
  step: AppSteps.start,
  cards: [],
  player: {
    data: initialUserState,
  },
};

export default function reducer(
  state: IAppState = initialState,
  action: IAppAction
): IAppState {
  switch (action.type) {
    case SET_STEP:
      return produce(state, (newState) => {
        const { payload } = action as ReturnType<typeof appActions.setStep>;

        newState.step = payload;
      });

    case SET_CARDS:
      return produce(state, (newState) => {
        const { payload } = action as ReturnType<typeof appActions.setCards>;

        newState.cards = payload;
      });

    case SET_PLAYER_REF:
      return produce(state, (newState) => {
        const { payload } = action as ReturnType<
          typeof appActions.setPlayerRef
        >;

        newState.player.ref = payload;
      });

    case GET_PLAYER.success:
      return produce(state, (newState) => {
        const { payload } = action as ReturnType<
          typeof appActions.getPlayer.success
        >;

        newState.player.data = payload;
      });

    case CONNECTED_TO_GAME_SUCCESS:
      return produce(state, (newState) => {
        const {
          payload: { player },
        } = action as ReturnType<typeof appActions.connectedToGameSuccess>;

        newState.player = player;
        newState.step = AppSteps.round;
      });

    case GET_CARDS.success:
      return produce(state, (newState) => {
        const { payload } = action as ReturnType<
          typeof appActions.getCards.success
        >;

        newState.cards = payload;
      });
    default:
      break;
  }

  return state;
}
