import * as firebase from "firebase/app";
import "firebase/firestore";
import firebaseConfig from "../firebaseConfig";

export let app: Firebase;
let firestore: firebase.firestore.Firestore;

export const COLLECTION_GAMES = "games";
export const COLLECTION_PLAYERS = "players";
export const COLLECTION_ROUNDS = "rounds";

export const SCORE_LIMIT = 30;

export const initialUserState = {
  id: "",
  username: "",
  score: 0,
  lastDiff: 0,
  queueOrder: -1,
  currentCards: [],
  icon: "",
  color: "",
  deltaTime: 0
};

export const init = () => {
  if (!firebase.apps.length) {
    app = new Firebase(firebase.initializeApp(firebaseConfig));
  } else {
    app = new Firebase(firebase.apps[0]);
  }
};

type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> &
  U[keyof U];
type GameParams = {
  code?: string;
  gameId?: string;
  username?: string;
  playerId?: string;
  clientTS: number;
};

class Firebase {
  app: firebase.app.App;

  constructor(app: firebase.app.App) {
    this.app = app;
  }

  callFn = (name: string, args: AtLeastOne<GameParams> = {}) =>
    this.app.functions('europe-west2').httpsCallable(name)(args);
  getFirestore = (): firebase.firestore.Firestore => this.app.firestore();
}
