import React from "react";
import block from "bem-cn";
import { connect } from "react-redux";
import cookies from "js-cookie";
import { AppSteps, IPlayer } from "../../redux/types/app";
import RoomIdInput from "../../components/Inputs/GameCodeInput";
import NameInput from "../../components/Inputs/NameInput";
import Round from "../../components/Round/Round";
import { stepSelector, playerRefSelector } from "../../redux/selectors/app";
import { appActions } from "../../redux/ducks/app";
import { gameRefSelector } from "../../redux/selectors/game";
import { IGameState } from "../../redux/types/game";
import { gameActions } from "../../redux/ducks/game";
import { IState } from "../../redux/types";
import { IRoundState } from "../../redux/types/round";
import { roundRefSelector } from "../../redux/selectors/round";
import { ReactComponent as IconExit } from "../../assets/icons/icon-exit.svg";
import {
  TIME_TO_START,
  CN,
  KIBANA_LOADED,
  KIBANA_NAME,
} from "../../utils/consts";
import { isFetchingLeaveGameSelector } from "../../redux/selectors/ui";
import ExitPopup from "../../components/ExitPopup/ExitPopup";
import { version } from "../../../package.json";
import "./App.scss";
import { postKibanaLog } from "../../utils";

const b = block("App");

interface IPropTypes {
  step: AppSteps;
  playerRef: IPlayer["ref"];
  gameRef: IGameState["ref"];
  roundRef: IRoundState["ref"];
  isFetchingLeaveGame: boolean;
}

interface State {}

interface DispatchProps {
  getGameFetch: typeof gameActions.getGame.fetch;
  setStep: typeof appActions.setStep;
  leaveGameFetch: typeof appActions.leaveGame.fetch;
  subscribeOnGame: typeof appActions.subscribeOnGame;
  subscribeOnRound: typeof appActions.subscribeOnRound;
  subscribeOnUser: typeof appActions.subscribeOnUser;
  unsubscribeFromGameEntities: typeof appActions.unsubscribeFromGameEntities;
}

type Props = IPropTypes & DispatchProps;

class App extends React.Component<Props, State> {
  state = {
    showExitPopup: false,
    exitWasClicked: false,
  };

  componentDidMount() {
    // to change step after first animation
    setTimeout(() => {
      const gameId = cookies.get(CN.GAME_ID);
      const playerId = cookies.get(CN.PLAYER_ID);

      if (gameId && playerId) {
        this.props.getGameFetch({ gameId, playerId });
      } else {
        this.props.setStep(AppSteps.enterGameCode);
      }
    }, TIME_TO_START);

    this.onResize();

    window.addEventListener("resize", this.onResize);
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.playerRef && this.props.playerRef) {
      this.props.subscribeOnUser(this.props.playerRef);
    }

    if (!prevProps.gameRef && this.props.gameRef) {
      this.props.subscribeOnGame(this.props.gameRef);
    }

    if (!prevProps.roundRef && this.props.roundRef) {
      this.props.subscribeOnRound(this.props.roundRef);
    }

    postKibanaLog(KIBANA_LOADED, {
      name: KIBANA_NAME,
      version,
      userAgent: window.navigator.userAgent,
      platform: window.navigator.platform || "",
    });
  }

  componentWillUnmount() {
    this.props.unsubscribeFromGameEntities();

    window.removeEventListener("resize", this.onResize);
  }

  onResize() {
    // hack to fix case resizing with screen address bar
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  getComponentByStep = () => {
    switch (this.props.step) {
      case AppSteps.enterGameCode:
        return <RoomIdInput />;
      case AppSteps.enterName:
        return <NameInput />;
      case AppSteps.round:
        return <Round />;
    }
  };

  showExitPopup = () => {
    this.setState({
      showExitPopup: true,
    });
  };

  hideExitPopup = () => {
    this.setState({
      showExitPopup: false,
    });
  };

  onExitGame = () => {
    this.props.leaveGameFetch();
    this.setState({
      exitWasClicked: true,
    });
  };

  render() {
    const { step, isFetchingLeaveGame } = this.props;
    const { exitWasClicked, showExitPopup } = this.state;
    const showCardsAnimation = step !== AppSteps.round;
    const showExit = ![AppSteps.start, AppSteps.enterGameCode].includes(step);

    return (
      <div
        className={b({
          Step_Start: step === AppSteps.start,
          Step_EnterRoom: step === AppSteps.enterGameCode,
          Step_EnterName: step === AppSteps.enterName,
          Step_Round: step === AppSteps.round,
        })}
      >
        {step === AppSteps.enterGameCode && version && (
          <div className={b("Version")}>{version}</div>
        )}
        {showExit && (
          <div className={b("ExitButton")} onClick={this.showExitPopup}>
            <IconExit />
          </div>
        )}
        {showCardsAnimation && (
          <>
            <div className={b("CardsContainer")}>
              <div className={b("Card")} />
              <div className={b("Card")} />
              <div className={b("Card")} />
              <div className={b("Card")} />
            </div>
            <div className={b("Title")}>Imagio</div>
          </>
        )}
        {this.getComponentByStep()}
        {showExitPopup && (
          <ExitPopup
            isFetchingExit={isFetchingLeaveGame}
            exitWasClicked={exitWasClicked}
            onHide={this.hideExitPopup}
            onExitGame={this.onExitGame}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: IState) => ({
  step: stepSelector(state),
  gameRef: gameRefSelector(state),
  roundRef: roundRefSelector(state),
  playerRef: playerRefSelector(state),
  isFetchingLeaveGame: isFetchingLeaveGameSelector(state),
});

const dispatchProps = {
  getGameFetch: gameActions.getGame.fetch,
  setStep: appActions.setStep,
  leaveGameFetch: appActions.leaveGame.fetch,
  subscribeOnUser: appActions.subscribeOnUser,
  subscribeOnGame: appActions.subscribeOnGame,
  subscribeOnRound: appActions.subscribeOnRound,
  unsubscribeFromGameEntities: appActions.unsubscribeFromGameEntities,
};

export default connect(mapStateToProps, dispatchProps)(App);
