import React from "react";
import "./PlayerIcon.scss";

interface IPropTypes {
  iconUrl: string;
  color: string;
}

const PlayerIcon: React.FC<IPropTypes> = React.memo(({ iconUrl, color }) => {
  return (
    <div
      className="PlayerIcon"
      style={{
        backgroundColor: color,
        backgroundImage: `url(assets/img/avatars/${iconUrl})`
      }}
    />
  );
});

PlayerIcon.displayName = "PlayerIcon";

export default PlayerIcon;
