import produce from "immer";
import { IAppAction } from "../utils";
import { IUiState, FetchStatus } from "../types/ui";
import { GET_GAME, FIND_GAME_BY_CODE } from "./game";
import { LEAVE_GAME, GET_CARDS } from "./app";

const initialState: IUiState = {
  gameFetchStatus: FetchStatus.idle,
  isFetchingLeaveGame: false,
  cardsFetchStatus: FetchStatus.idle,
};

export default function reducer(
  state: IUiState = initialState,
  action: IAppAction
): IUiState {
  switch (action.type) {
    case GET_GAME.fetch:
    case FIND_GAME_BY_CODE.fetch:
      return produce(state, (newState) => {
        newState.gameFetchStatus = FetchStatus.fetching;
      });

    case GET_GAME.success:
    case FIND_GAME_BY_CODE.success:
      return produce(state, (newState) => {
        newState.gameFetchStatus = FetchStatus.success;
      });

    case GET_GAME.fail:
    case FIND_GAME_BY_CODE.fail:
      return produce(state, (newState) => {
        newState.gameFetchStatus = FetchStatus.failed;
      });

    case LEAVE_GAME.fetch:
      return produce(state, (newState) => {
        newState.isFetchingLeaveGame = true;
      });

    case LEAVE_GAME.success:
    case LEAVE_GAME.fail:
      return produce(state, (newState) => {
        newState.isFetchingLeaveGame = false;
      });

    case GET_CARDS.fetch:
      return produce(state, (newState) => {
        newState.cardsFetchStatus = FetchStatus.fetching;
      });

    case GET_CARDS.success:
      return produce(state, (newState) => {
        newState.cardsFetchStatus = FetchStatus.success;
      });

    case GET_CARDS.fail:
      return produce(state, (newState) => {
        newState.cardsFetchStatus = FetchStatus.failed;
      });

    default:
      break;
  }

  return state;
}
