import { combineReducers } from "redux";
import app from "./app";
import game from "./game";
import ui from "./ui";
import round from "./round";

const reducers = combineReducers({
  app,
  game,
  ui,
  round
});

export default reducers;
