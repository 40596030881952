import { DocumentReference, DocumentData } from "@firebase/firestore-types";

export interface IAppState {
  step: AppSteps;
  cards: ICard[];
  player: IPlayer;
}

export interface ICard {
  id: string;
  skin: string;
  ["1"]: string; // nonretina
  ["2"]: string; // retina
  ["3"]: string; // super retina
}

export interface IPlayer {
  ref?: DocumentReference<DocumentData>;
  data: {
    id: string;
    username: string;
    color: string;
    currentCards: Array<ICard["id"]>;
    icon: string;
    queueOrder: number;
    lastDiff?: number;
    score: number;
    deltaTime: number; // ms
  };
}

export enum AppSteps {
  start = "start",
  enterGameCode = "enterGameCode",
  enterName = "enterName",
  round = "round",
}
