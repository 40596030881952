import { createSelector } from "reselect";
import { IState } from "../types";

export const roundSelector = (state: IState) => state.round;

export const roundDataSelector = createSelector(
  roundSelector,
  round => round.data
);

export const roundRefSelector = createSelector(
  roundSelector,
  round => round.ref
);
