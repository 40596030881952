import confetti, { shape } from "canvas-confetti";
import { GameStatuses } from "../../redux/types/game";

const CONFETTI_COLORS = ["#DFC00C", "#D51F67", "#00A4FF", "#FF91B6", "#79B12D"];

const winnerConfettiConfig = {
  particleCount: CONFETTI_COLORS.length,
  angle: 60,
  spread: 90,
  decay: 0.93,
  shapes: ["circle", "square", "circle", "square"] as shape[],
  origin: { x: 0, y: 1 },
  colors: CONFETTI_COLORS,
};

export function missedMoveTextResolver(
  gameStatus: GameStatuses,
  getTranslate: (key: string) => string
) {
  let res = "";

  switch (gameStatus) {
    case GameStatuses.waitAssociation:
    case GameStatuses.showScore:
      res = getTranslate("youMissedMakingAssociation");
      break;
    case GameStatuses.waitMoves:
      res = getTranslate("youMissedMoving");
      break;
    case GameStatuses.waitVotes:
    case GameStatuses.showResults:
      res = getTranslate("dontForgetToVote");
      break;
    default:
      res = getTranslate("youMissedTurn");
      break;
  }

  return res;
}

export function createConfettiAnimation() {
  const canvas = document.createElement("canvas");
  const end = Date.now() + 1 * 1000;

  canvas.classList.add("WinnerConfetti");
  document.body.appendChild(canvas);

  const winnerConfetti = confetti.create(canvas, {
    resize: true,
    useWorker: true,
  });

  frame();

  function frame() {
    winnerConfetti(winnerConfettiConfig);

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  }
}

export function gameStatusMap(value: GameStatuses) {
  return {
    new: value === GameStatuses.new,
    waitVotes: value === GameStatuses.waitVotes,
    waitMoves: value === GameStatuses.waitMoves,
    waitAssociation: value === GameStatuses.waitAssociation,
    showResults: value === GameStatuses.showResults,
    showScore: value === GameStatuses.showScore,
  };
}
