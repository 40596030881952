import produce from "immer";
import {
  createActionType,
  TypeCreator,
  createFetchTypes,
  createFetchActionCreators,
  IAppAction,
  createAction
} from "../utils";
import { IGameState, IGameData, GameStatuses } from "../types/game";
import { IPlayer } from "../types/app";

const createType = createActionType("game") as TypeCreator;

export const GET_GAME = createFetchTypes(createType("GET_GAME"));
export const SET_GAME_CODE = createType("SET_GAME_CODE");
export const GET_WINNERS = createFetchTypes(createType("GET_WINNERS"));
export const SET_GAME_STATUS = createType("SET_GAME_STATUS");
export const FIND_GAME_BY_CODE = createFetchTypes(
  createType("FIND_GAME_BY_CODE")
);

export const gameActions = {
  getGame: createFetchActionCreators<
    {
      gameCode?: IGameData["code"];
      playerName?: IPlayer["data"]["username"];
      gameId?: string;
      playerId?: string;
    },
    { data: IGameData; ref?: IGameState["ref"] }
  >(GET_GAME),
  setGameCode: (code: IGameData["code"]) => createAction(SET_GAME_CODE, code),
  getWinners: createFetchActionCreators<void, Array<IPlayer["data"]>>(
    GET_WINNERS
  ),
  setGameStatus: (status: GameStatuses) =>
    createAction(SET_GAME_STATUS, status),
  findGameByCode: createFetchActionCreators<string, IGameData>(
    FIND_GAME_BY_CODE
  )
};

const initialState: IGameState = {
  data: {
    assets: [],
    bazar: [],
    currentRound: "",
    id: "",
    code: "",
    status: GameStatuses.initial,
    usedDecks: [],
    pause: {
      enabled: false,
      secondsLeft: 0,
      updated: {
        seconds: 0,
        nanoseconds: 0,
      }
    }
  },
  winners: []
};

export default function reducer(
  state: IGameState = initialState,
  action: IAppAction
): IGameState {
  switch (action.type) {
    case GET_GAME.success:
      return produce(state, newState => {
        const {
          payload: { data, ref }
        } = action as ReturnType<typeof gameActions.getGame.success>;

        newState.data = data;

        if (ref) {
          newState.ref = ref;
        }
      });

    case SET_GAME_CODE:
      return produce(state, newState => {
        const { payload } = action as ReturnType<
          typeof gameActions.setGameCode
        >;

        newState.data.code = payload;
      });

    case GET_WINNERS.success:
      return produce(state, newState => {
        const { payload } = action as ReturnType<
          typeof gameActions.getWinners.success
        >;

        newState.winners = payload;
      });

    case FIND_GAME_BY_CODE.success:
      return produce(state, newState => {
        const { payload } = action as ReturnType<
          typeof gameActions.findGameByCode.success
        >;

        newState.data = payload;
      });

    default:
      break;
  }

  return state;
}
