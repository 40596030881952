import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";
import ProgressBarRound from "../ProgressBarCircle/ProgressBarCircle";
import { playerDataSelector, cardsSelector } from "../../redux/selectors/app";
import { ICard } from "../../redux/types/app";
import { roundActions } from "../../redux/ducks/round";
import {
  gameStatusSelector,
  winnersSelector,
  gameDataSelector,
  gamePauseSelector,
  gameStatusUpdatedSecondsSelector,
} from "../../redux/selectors/game";
import { GameStatuses } from "../../redux/types/game";
import { roundDataSelector } from "../../redux/selectors/round";
import { gameActions } from "../../redux/ducks/game";
import {
  RoundHeaderComponent,
  b,
  RoundContentComponent,
  MasterControlButton,
  RoundMissedPopup,
} from "./RoundComponents";
import { missedMoveTextResolver, gameStatusMap } from "./utils";
import "./Round.scss";
import { getTimerValue } from "../../utils";
import { cardsFetchStatusSelector } from "../../redux/selectors/ui";
import { FetchStatus } from "../../redux/types/ui";
import { appActions } from "../../redux/ducks/app";

// TODO: there are a lot of useEffects, don't you?
// need to separate some logic

const Round = React.memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const playerData = useSelector(playerDataSelector);
  const allCards = useSelector(cardsSelector);
  const gameStatus = useSelector(gameStatusSelector);
  const roundData = useSelector(roundDataSelector);
  const winners = useSelector(winnersSelector);
  const gameData = useSelector(gameDataSelector);
  const gamePause = useSelector(gamePauseSelector);
  const gsUpdatedSeconds = useSelector(gameStatusUpdatedSecondsSelector);
  const cardsFetchStatus = useSelector(cardsFetchStatusSelector);

  const missedMoveData = useRef<{ rId?: string; gameStatus?: GameStatuses }>(
    {}
  );

  const [masterControlClicked, setMasterControlClicked] = useState(false);
  const [timerRunning, setTimerRunning] = useState(false);
  const [missedMove, setMissedMove] = useState(false);
  const [missedMoveText, setMissedMoveText] = useState<string | void>();
  const [timerValue, setTimerValue] = useState(0);

  const GS = useMemo(() => gameStatusMap(gameStatus), [gameStatus]);

  const isMaster = useMemo(() => playerData.id === roundData.master.id, [
    playerData.id,
    roundData.master,
  ]);

  const isWaitingChoose = useMemo(
    () => GS.waitMoves || GS.waitVotes || GS.waitAssociation,
    [GS]
  );

  const moveOrVoteWasMade = useMemo(() => {
    const key = GS.waitMoves ? "moves" : "votes";

    if (isWaitingChoose) {
      return isMaster
        ? playerData.id in roundData.moves
        : playerData.id in roundData[key];
    }

    return false;
  }, [isMaster, GS.waitMoves, isWaitingChoose, roundData, playerData]);

  const showLoader = useMemo(() => {
    return (
      GS.new ||
      (GS.waitAssociation && moveOrVoteWasMade && isMaster) ||
      (GS.waitAssociation && roundData.master.id === "") ||
      missedMove
    );
  }, [GS, roundData.master, moveOrVoteWasMade, isMaster, missedMove]);

  const showControlButton = useMemo(
    () => isMaster && (GS.showResults || GS.showScore),
    [isMaster, GS]
  );

  const isPauseWasToggled = useMemo(
    () => gamePause.updated.seconds > gsUpdatedSeconds,
    [gamePause.updated.seconds, gsUpdatedSeconds]
  );

  const moveCardIds = useMemo(
    () =>
      roundData.movesShuffled
        ? roundData.movesShuffled
            .filter((pId) => pId !== playerData.id)
            .map((pId) => roundData.moves[pId])
        : [],
    [roundData.movesShuffled, playerData.id, roundData.moves]
  );

  const allCardsObj = useMemo(
    () =>
      allCards.reduce((accum: { [key: string]: ICard }, current) => {
        accum[current.id] = current;
        return accum;
      }, {}),
    [allCards]
  );

  const cards = useMemo(() => {
    switch (gameStatus) {
      case GameStatuses.waitAssociation:
      case GameStatuses.waitMoves:
        return allCards.filter(
          (c) => playerData.currentCards.indexOf(c.id) !== -1
        );
      case GameStatuses.waitVotes:
        return moveCardIds.map((id) => allCardsObj[id]).filter((c) => c);
      default:
        return [];
    }
  }, [gameStatus, allCards, playerData.currentCards, moveCardIds, allCardsObj]);

  const onClickCard = useCallback(
    (id: ICard["id"]) => {
      switch (gameStatus) {
        case GameStatuses.waitVotes:
          dispatch(roundActions.insertVote(id, playerData.id));
          break;

        case GameStatuses.waitAssociation:
        case GameStatuses.waitMoves:
          dispatch(roundActions.insertMove(id, playerData.id));
          break;
      }

      if (timerRunning) {
        setTimerRunning(false);
      }

      // to reset scroll position after moving/voting
      window.scrollTo(0, 0);
    },
    [dispatch, gameStatus, playerData.id, timerRunning]
  );

  const onClickControlArrow = useCallback(() => {
    let status;

    switch (gameStatus) {
      case GameStatuses.showResults:
        status = GameStatuses.showScore;
        break;
      case GameStatuses.showScore:
        status = GameStatuses.started;
        dispatch(roundActions.resetMaster());
        break;
    }

    setMasterControlClicked(false);
    status && dispatch(gameActions.setGameStatus(status));
  }, [gameStatus, dispatch]);

  const onStartButtonAnimationEnd = useCallback(
    ({ animationName }) => {
      if (animationName === "scale-bound") {
        onClickControlArrow();
      }
    },
    [onClickControlArrow]
  );

  const onTouchStartButton = useCallback(() => {
    if (!masterControlClicked) {
      setMasterControlClicked(true);
    }
  }, [masterControlClicked]);

  const onTimerDone = useCallback(() => {
    setTimerRunning(false);
    setMissedMoveText(missedMoveTextResolver(gameStatus, t));

    missedMoveData.current = {
      rId: gameData.currentRound,
      gameStatus,
    };
  }, [gameStatus, gameData.currentRound, t]);

  const onSubmitMissedMove = useCallback(() => {
    setMissedMoveText();

    if (
      missedMoveData.current.rId === gameData.currentRound &&
      missedMoveData.current.gameStatus === gameStatus
    ) {
      setMissedMove(true);
    }

    missedMoveData.current = {};
  }, [gameData.currentRound, gameStatus]);

  const onGetTimerValue = useCallback(() => {
    let res = 0;

    if (gameData.timeoutSeconds) {
      const timeout = isPauseWasToggled
        ? gamePause.secondsLeft
        : gameData.timeoutSeconds;
      const started = isPauseWasToggled
        ? gamePause.updated.seconds
        : gsUpdatedSeconds;

      res = getTimerValue(timeout, started, playerData.deltaTime);
    }

    return res;
  }, [
    playerData.deltaTime,
    gameData.timeoutSeconds,
    gamePause,
    gsUpdatedSeconds,
    isPauseWasToggled,
  ]);

  useEffect(() => {
    switch (gameStatus) {
      case GameStatuses.waitAssociation:
        if (isMaster) {
          setTimerRunning(!moveOrVoteWasMade);
        } else if (timerRunning) {
          // TODO: master resets so slow
          setTimerRunning(false);
        }

        break;
      case GameStatuses.waitMoves:
      case GameStatuses.waitVotes:
        setTimerRunning(!moveOrVoteWasMade);
        break;

      case GameStatuses.showScore:
        if (!isMaster) {
          dispatch(roundActions.resetMaster());
        }
        break;

      case GameStatuses.finished:
        if (!winners.length) {
          dispatch(gameActions.getWinners.fetch());
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStatus, moveOrVoteWasMade, isMaster]);

  useEffect(() => {
    if (missedMove) {
      setMissedMove(false);
    }

    if (timerRunning) {
      setTimerRunning(false);
    }

    if (cardsFetchStatus === FetchStatus.idle) {
      dispatch(appActions.getCards.fetch(gameData.usedDecks));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStatus]);

  useEffect(() => {
    let seconds = 0;

    if (isWaitingChoose && gameData.timeoutSeconds && !gamePause.enabled) {
      const timeout = isPauseWasToggled
        ? gamePause.secondsLeft
        : gameData.timeoutSeconds;
      const started = isPauseWasToggled
        ? gamePause.updated.seconds
        : gsUpdatedSeconds;

      seconds = getTimerValue(timeout, started, playerData.deltaTime);
    }

    setTimerValue(seconds);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    playerData.deltaTime,
    gameData.timeoutSeconds,
    gsUpdatedSeconds,
    gamePause.enabled,
  ]);

  return (
    <div className={b()}>
      <div className={b("Header")}>
        <RoundHeaderComponent
          gameStatus={gameStatus}
          isMaster={isMaster}
          playerData={playerData}
          missedMove={missedMove}
          moveOrVoteWasMade={moveOrVoteWasMade}
        />
      </div>
      {showLoader ? (
        <div className={b("LoaderContainer")}>
          <Loader />
        </div>
      ) : (
        <div className={b("Content")}>
          <RoundContentComponent
            gameStatus={gameStatus}
            playerData={playerData}
            cards={cards}
            onClickCard={onClickCard}
            winners={winners}
            moveOrVoteWasMade={moveOrVoteWasMade}
            isMaster={isMaster}
            master={roundData.master}
          />
        </div>
      )}

      {gameStatus === GameStatuses.new && (
        <div className={b("Content")}>
          <div className="Round__ContentTitle">{t("waitForOthers")}</div>
        </div>
      )}
      {missedMove && (
        <div className={b("Content")}>
          <div className="Round__ContentTitle">{t("missedMove")}</div>
        </div>
      )}

      {showControlButton && (
        <MasterControlButton
          showResults={GS.showResults}
          masterControlClicked={masterControlClicked}
          onTouchStartButton={onTouchStartButton}
          onStartButtonAnimationEnd={onStartButtonAnimationEnd}
        />
      )}

      {timerRunning && Boolean(timerValue) && !gamePause.enabled && (
        <div className={b("TimerContainer")}>
          <ProgressBarRound
            initialValue={timerValue}
            max={gameData.timeoutSeconds}
            isRunning={timerRunning}
            onTimerDone={onTimerDone}
            className="Round__Timer"
            getValue={onGetTimerValue}
          />
        </div>
      )}

      {missedMoveText && (
        <RoundMissedPopup text={missedMoveText} onSubmit={onSubmitMissedMove} />
      )}
    </div>
  );
});

Round.displayName = "Round";

export default Round;
