import { DocumentReference, DocumentData } from "@firebase/firestore-types";
import { IPlayer } from "./app";

export interface IGameState {
  data: IGameData;
  winners: Array<IPlayer["data"]>;
  ref?: DocumentReference<DocumentData>;
}

export interface IGameData {
  assets: IGameAsset[];
  bazar: Array<string>;
  currentRound: string;
  id: string;
  code: string;
  status: GameStatuses;
  usedDecks: Array<string>;
  timeoutSeconds?: number; // s
  statusUpdated?: {
    seconds: number; // timestamp
    nanoseconds: number;
  };
  pause?: {
    enabled: boolean;
    secondsLeft: number,
    updated: {
      seconds: number;
      nanoseconds: number;
    }
  }
}

export interface IGameAsset {
  color: string;
  icon: string;
}

export interface IConnectExistGameResponse {
  data: {
    player: IPlayer["data"];
    game: IGameData;
  };
}

export interface IConnectNewGameResponse {
  data: {
    player: IPlayer["data"];
    gameId: string;
  };
}

export enum GameStatuses {
  initial = "initial",
  new = "new",
  started = "started",
  waitAssociation = "wait_association",
  waitMoves = "wait_moves",
  waitVotes = "wait_votes",
  showResults = "show_results",
  showScore = "show_score",
  finished = "finished"
}
