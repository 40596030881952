export default {
  ru: {
    translation: {
      enterGameCode: "Введите код, чтобы подключиться к игре на телевизоре!",
      whatIsYourName: "Как вас зовут?",
      missedMove: "Вы не успели. Ждите следующий раунд",
      waitForOthers: "Ждем остальных",
      makeAssociate: "Загадайте ассоциацию!",
      chooseCard: "Выберите подходящую карту!",
      playerIsMakingAssociation: "\n загадывает ассоциацию",
      playersAreMoving: "Игроки ходят",
      playerAreVoting: "Игроки голосуют",
      yourScoresPerRound: "Ваши заработанные очки за раунд",
      totalScores: "Общее количество очков",
      results: "результаты",
      next: "далее",
      youMissedMakingAssociation:
        "Вы не успели загадать ассоциацию. Теперь ходит следующий игрок",
      youMissedMoving:
        "Вы опоздали. Но можете голосовать за карты других игроков",
      dontForgetToVote: "Не забывайте голосовать или не заработаете очков",
      youMissedTurn: "Вы не успели",
      areYouSureWantToExit: "Вы точно хотите выйти из игры?",
      exit: "выйти",
      stay: "остаться",
      newGame: "Новая игра",
      youWin: 'вы победили! Поздравляем ;)'
    },
  },
  uk: {
    translation: {
      enterGameCode: "Введіть код, щоб підключитися до гри на телевізорі!",
      whatIsYourName: "Як вас звати?",
      missedMove: "Ви не встигли. Чекайте наступний раунд",
      waitForOthers: "Чекаємо на інших",
      makeAssociate: "Загадайте асоціацію!",
      chooseCard: "Виберіть відповідну карту!",
      playerIsMakingAssociation: "\n загадує асоціацію",
      playersAreMoving: "Гравці ходять",
      playerAreVoting: "Гравці голосують",
      yourScoresPerRound: "Ваші зароблені очки за раунд",
      totalScores: "Загальна кількість очок",
      results: "результати",
      next: "далі",
      youMissedMakingAssociation:
        "Ви не встигли загадати асоціацію. Тепер ходить наступний гравець",
      youMissedMoving:
        "Ви запізнилися. Але можете голосувати за карти інших гравців",
      dontForgetToVote: "Не забувайте голосувати. Інакше не заробите очок",
      youMissedTurn: "Ви не встигли",
      areYouSureWantToExit: "Ви точно бажаєте вийти з гри?",
      exit: "вийти",
      stay: "залишитися",
      newGame: "Нова гра",
      youWin: 'ви перемогли! Вітаємо ;)'
    },
  },
  en: {
    translation: {
      enterGameCode: "Enter the code to connect the game on the TV!",
      whatIsYourName: "What's your name?",
      missedMove: "You're too late. Wait for the next round",
      waitForOthers: "Waiting for other players",
      makeAssociate: "Make up an association!",
      chooseCard: "Choose the right card!",
      playerIsMakingAssociation: "\n is making up an association",
      playersAreMoving: "Players are selecting cards",
      playerAreVoting: "Players are voting",
      yourScoresPerRound: "Points you've scored for the round",
      totalScores: "Total score",
      results: "results",
      next: "next",
      youMissedMakingAssociation:
        "You failed to make up an association on time. Now the next player's turn",
      youMissedMoving:
        "You're late. But you can vote for cards of other players",
      dontForgetToVote: "Do not forget to vote. Or you won't score points",
      youMissedTurn: "You're late",
      areYouSureWantToExit: "Are you sure you want to quit the game?",
      exit: "quit",
      stay: "resume",
      newGame: "New game",
	  youWin: "you've won! Congratulations ;)"
    },
  },
};
