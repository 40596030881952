import React from "react";
import { ReactComponent as IconSpinner } from "../../assets/icons/icon-spinner.svg";
import "./Loader.scss";

interface IPropTypes {
  position?: "absolute" | "relative";
  color?: string;
}

const Loader: React.FC<IPropTypes> = ({
  position = "relative",
  color = "#dfc00c"
}) => (
  <div className="Loader" style={{ position }}>
    <IconSpinner stroke={color} />
  </div>
);

Loader.displayName = "Loader";

export default Loader;
