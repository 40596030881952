import React, { useCallback } from "react";
import block from "bem-cn";
import { useTranslation } from "react-i18next";
import { GameStatuses } from "../../redux/types/game";
import { IPlayer, ICard } from "../../redux/types/app";
import PlayerIcon from "../PlayerIcon/PlayerIcon";
import Cards from "../Cards/Cards";
import Loader from "../Loader/Loader";
import { IRoundData } from "../../redux/types/round";
import { createConfettiAnimation } from "./utils";
import { clearCookies, getTruncatedString } from "../../utils";
import { CN } from "../../utils/consts";

export const b = block("Round");

interface IRoundHeaderComponent {
  gameStatus: GameStatuses;
  isMaster: boolean;
  playerData: IPlayer["data"];
  moveOrVoteWasMade: boolean;
  missedMove: boolean;
}

export const RoundHeaderComponent: React.FC<IRoundHeaderComponent> = React.memo(
  ({ gameStatus, isMaster, playerData, moveOrVoteWasMade, missedMove }) => {
    const { t } = useTranslation();

    let res = <div />;

    if (!missedMove) {
      switch (gameStatus) {
        case GameStatuses.new:
          res = (
            <PlayerIcon iconUrl={playerData.icon} color={playerData.color} />
          );
          break;
        case GameStatuses.waitAssociation:
          if (isMaster && !moveOrVoteWasMade) {
            res = <div className={b("HeaderTitle")}>{t("makeAssociate")}</div>;
          } else {
            res = (
              <PlayerIcon iconUrl={playerData.icon} color={playerData.color} />
            );
          }
          break;

        case GameStatuses.waitVotes:
        case GameStatuses.waitMoves:
          if (!moveOrVoteWasMade) {
            res = <div className={b("HeaderTitle")}>{t("chooseCard")}</div>;
          }
          break;

        case GameStatuses.showScore:
        case GameStatuses.showResults:
        case GameStatuses.finished:
          res = (
            <PlayerIcon iconUrl={playerData.icon} color={playerData.color} />
          );
          break;
      }
    }

    return res;
  }
);

RoundHeaderComponent.displayName = "RoundHeaderComponent";

interface IRoundContentComponent {
  gameStatus: GameStatuses;
  playerData: IPlayer["data"];
  cards: ICard[];
  onClickCard: (id: ICard["id"]) => void;
  winners: Array<IPlayer["data"]>;
  moveOrVoteWasMade: boolean;
  isMaster: boolean;
  master: IRoundData["master"];
}

export const RoundContentComponent: React.FC<IRoundContentComponent> = React.memo(
  ({
    gameStatus,
    playerData,
    cards,
    onClickCard,
    winners,
    moveOrVoteWasMade,
    isMaster,
    master,
  }) => {
    const { t } = useTranslation();

    let res = <div />;

    const onClickNewGame = useCallback(() => {
      clearCookies([CN.PLAYER_ID, CN.GAME_ID]);
      window.location.reload(); //TODO: find another way to reset all
    }, []);

    switch (gameStatus) {
      case GameStatuses.waitAssociation:
        res = isMaster ? (
          <Cards
            list={cards}
            onClickCard={onClickCard}
            gameStatus={gameStatus}
          />
        ) : (
          <RoundContentLoader>
            <div className={b("WaitAssociationTitle")}>
              <div className={b("MasterName")}>{master.username}</div>
              {t("playerIsMakingAssociation")}
            </div>
          </RoundContentLoader>
        );
        break;

      case GameStatuses.waitMoves:
        res = moveOrVoteWasMade ? (
          <RoundContentLoader>
            {isMaster ? t("playersAreMoving") : t("waitForOthers")}
          </RoundContentLoader>
        ) : (
          <Cards
            list={cards}
            onClickCard={onClickCard}
            gameStatus={gameStatus}
          />
        );
        break;

      case GameStatuses.waitVotes:
        res = moveOrVoteWasMade ? (
          <RoundContentLoader>
            {isMaster ? t("playersAreMoving") : t("waitForOthers")}
          </RoundContentLoader>
        ) : (
          <Cards
            list={cards}
            onClickCard={onClickCard}
            gameStatus={gameStatus}
          />
        );
        break;

      case GameStatuses.showResults:
        res = (
          <div>
            <div className="Round__ContentTitle">{t("yourScoresPerRound")}</div>
            <div className="Round__Result">
              {`+${playerData.lastDiff ? playerData.lastDiff : 0}`}
            </div>
          </div>
        );
        break;

      case GameStatuses.showScore:
        res = (
          <div>
            <div className="Round__ContentTitle">{t("totalScores")}</div>
            <div className="Round__Result">{playerData.score}</div>
          </div>
        );
        break;

      case GameStatuses.finished:
        const youWinn = winners.find((w) => w.id === playerData.id);
        youWinn && createConfettiAnimation();

        res = (
          <div className="Round__Finished">
            {youWinn ? (
              <div>
                <div className="Round__WinnerResult">{playerData.score}</div>
                <div className="Round__WinnerTitle">
                  {`${getTruncatedString(playerData.username, 10)}, \n ${t(
                    "youWin"
                  )}`}
                </div>
              </div>
            ) : (
              <div className="Round__Result">{playerData.score}</div>
            )}
            <div className="Button" onClick={onClickNewGame}>
              {t("newGame")}
            </div>
          </div>
        );

        break;
    }

    return res;
  }
);

RoundContentComponent.displayName = "RoundContentComponent";

interface IMasterControlButton {
  showResults: boolean;
  masterControlClicked: boolean;
  onTouchStartButton: () => void;
  onStartButtonAnimationEnd: (e: React.AnimationEvent) => void;
}

export const MasterControlButton: React.FC<IMasterControlButton> = React.memo(
  ({
    showResults,
    masterControlClicked,
    onTouchStartButton,
    onStartButtonAnimationEnd,
  }) => {
    const { t } = useTranslation();

    return (
      <div
        className={`Button ${b("ControlButton", {
          State_Clicked: masterControlClicked,
        })}`}
        onTouchStart={onTouchStartButton}
        onAnimationEnd={onStartButtonAnimationEnd}
      >
        {showResults ? t("results") : t("next")}
      </div>
    );
  }
);

MasterControlButton.displayName = "MasterControlButton";

const RoundContentLoader: React.FC = (props) => (
  <div className={b("ContentLoader")}>
    <Loader />
    <div className={b("ContentTitle")}>{props.children}</div>
  </div>
);

RoundContentLoader.displayName = "RoundContentLoader";

export const RoundMissedPopup: React.FC<{
  text: string;
  onSubmit: () => void;
}> = ({ text, onSubmit }) => (
  <div className={b("MissedMove")}>
    <div className={b("MissedMovePopup")}>
      <div className={b("MissedMoveText")}>{text}</div>
      <div className="Button" onClick={onSubmit}>
        ок
      </div>
    </div>
  </div>
);

RoundMissedPopup.displayName = "RoundMissedPopup";
