export const CN = {
  GAME_CODE: "img_g_code",
  GAME_ID: "img_g_id",
  PLAYER_ID: "img_p_id",
};

export const COOKIES_LIFE_TIME = 1 / 24;

export const TIME_TO_START = 2500;

export const CARDS_HASH = Date.now(); // to prevent caching on devices
export const KIBANA_URL = "https://log.cnt.re/log/cms_landing";
export const KIBANA_NAME = "imagio-mob";
export const KIBANA_ERROR_TYPE = "GAME_ERROR_MOB";
export const KIBANA_LOADED = "GAME_LOADED_MOB";
