import { createSelector } from "reselect";
import { IState } from "../types";

export const uiSelector = (state: IState) => state.ui;

export const gameFetchStatusSelector = createSelector(
  uiSelector,
  (ui) => ui.gameFetchStatus
);

export const isFetchingLeaveGameSelector = createSelector(
  uiSelector,
  (ui) => ui.isFetchingLeaveGame
);

export const cardsFetchStatusSelector = createSelector(
  uiSelector,
  (ui) => ui.cardsFetchStatus
);
